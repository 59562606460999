<template>
  <div class="company-profile-ai-gen">
    <div class="company-profile-ai-gen-header">
      AI-Generated Shipments Summary
      <el-popover
        placement="bottom"
        width="300"
        trigger="hover"
        popper-class="ai-gen-popover"
        content="AI-generated content may contain inaccurate information. Please verify the data with external sources. External links are provided for reference and are not affiliated with ImportGenius.">
        <el-button slot="reference"> <span class="gen-ai-icon" /></el-button>
      </el-popover>
    </div>
    <div
      v-loading="!profiles.middle_points"
      class="company-profile-ai-gen-content ai-gen-shipment-summary">

      <div class="row no-gutters">
        <div class="col-md-6">

          <div class="ai-gen-shipment-summary-sub">Related News</div>
          <div
            :class="{ 'ai-gen-content-clamp': showClass }"
            class="mb-0 ai-gen-content-overview"
            v-html="profiles.News"/>
          <button 
            class="btn-text" 
            @click="clickReadMore">
            <template v-if="showClass">
              Show more
            </template>
            <template v-else>
              Show less
            </template>
          </button>
          <gen-ai-ratings 
            :rating="ratings.rating" 
            @update-rating="editRating"/>
        </div>
        
        <div class="col-md-6">
          <div class="ai-gen-shipment-summary-sub">Potential Synergies</div>
          <div
            :class="{ 'ai-gen-content-clamp': showClass2 }"
            class="mb-0 ai-gen-content-overview"
            v-html="profiles.middle_points"/>
          <button 
            class="btn-text" 
            @click="clickReadMore2">
            <template v-if="showClass2">
              Show more
            </template>
            <template v-else>
              Show less
            </template>
          </button>
          <gen-ai-ratings 
            :rating="ratings.rating" 
            @update-rating="editRating"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ts-ignore
import GenAiRatings from '~/components/common/GenAiRatings'
import { mapActions } from 'vuex'
export default {
  name: 'ShipmentSummaryAiGen', // This is a temporary name. Feel free to rename it accordingly.
  components: {
    GenAiRatings
  },
  props: {
    profiles: {
      type: Object,
      required: true
    },
    ratings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showClass: true,
      showClass2: true,
      showMoreText: 'Show more'
    }
  },
  methods: {
    ...mapActions('company', ['editRating']),
    clickReadMore() {
      this.showClass = !this.showClass
    },
    clickReadMore2() {
      this.showClass2 = !this.showClass2
    }
  }
}
</script>

import { render, staticRenderFns } from "./BookmarkPopover.vue?vue&type=template&id=3749d92e&"
import script from "./BookmarkPopover.vue?vue&type=script&lang=js&"
export * from "./BookmarkPopover.vue?vue&type=script&lang=js&"
import style0 from "~/assets/scss/sections/bookmark/bookmark.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
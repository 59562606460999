<template>
  <div>
    <div class="company-profile-section">
      <shipment-summary-ai-gen
        :profiles="profiles"
        :ratings="ratings" />
    </div>
    <div class="company-profile-section">
      <div class="company-profile-section-header">Shipment Stats</div>
      <company-info-navigation
        :stat="stat"
        :interval-scale="dateInterval"
        class="company-profile-shipment-filter"
        @dateInterval="intervalf"/>

      <div class="stats-graph">
        <div class="chart-row line-graphs-row">
          <company-shipments-graph
            :show-header="false"
            chart-class="graph-container" />
        </div>
      </div>
    </div>

    <div class="company-profile-section pl-0 pr-0">
      <shipments-data-viewer
        :class="{ 'full-screen': inFullscreen }"
        :loading="$actions.pending('company/shipments/fetchShipments')"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :date-range-text="dateRangeText"
        :total="totalShipments"
        :current-page="currentPage"
        :has-checkboxes="false"
        :has-bookmark="false"
        :page-limit="pageLimit"
        :shipments="shipments"
        :show-cards-icon="true"
        @sort="handleSortChange"
        @limit="handleLimitChange"
        @page="handlePageChange"
        @view-modal="setCustomViewOpen" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CompanyInfoNavigation from '~/components/company/CompanyInfoNavigation'
import CompanyShipmentsGraph from '~/components/company/CompanyShipmentsGraph'
import ShipmentsDataViewer from '~/components/data-viewers/ShipmentsDataViewer'
import ShipmentSummaryAiGen from '~/components/company/ShipmentSummaryAiGen'
import { companyProfileGenAiMixin } from '~/mixins/companyProfileGenAiMixin'

export default {
  name: 'ShipmentsPage',
  components: {
    CompanyInfoNavigation,
    CompanyShipmentsGraph,
    ShipmentsDataViewer
  },
  mixins: [companyProfileGenAiMixin],
  computed: {
    ...mapState('company', ['stat', 'dateInterval', 'profiles', 'ratings']),
    ...mapState(['inFullscreen']),
    ...mapGetters('company/overview', ['dateRangeText']),
    ...mapState('company', ['stat']),
    ...mapState('company/shipments', [
      'sortField',
      'sortOrder',
      'totalShipments',
      'shipments',
      'pageLimit',
      'currentPage'
    ])
  },
  watch: {
    async $route(to) {
      const { p: page, r: limit } = to.query

      if (
        parseInt(page) !== this.currentPage ||
        parseInt(limit) !== this.pageLimit
      ) {
        this.setCurrentPage(parseInt(page))
        this.setPageLimit(parseInt(limit))
        this.$store.dispatch('company/shipments/fetchShipments')
      }
    }
  },
  mounted() {
    this.updateQueryParams()
  },
  updated() {
    this.updateQueryParams()
  },
  created() {
    if (this.isCompanyProfileGenAiEnabled) {
      this.$options.components.ShipmentSummaryAiGen = ShipmentSummaryAiGen
    }
  },
  methods: {
    ...mapMutations('company/shipments', [
      'setSorting',
      'setCurrentPage',
      'setPageLimit'
    ]),
    ...mapMutations('company', ['setCustomViewOpen', 'setDateInterval']),
    ...mapActions('company', ['fetchShipmentsData']),
    intervalf(interval) {
      this.setDateInterval(interval)
      this.fetchShipmentsData()
    },
    findIndexes(currentPage, pageLimit) {
      const start = (currentPage - 1) * pageLimit
      const end = start + pageLimit
      return { start, end }
    },
    handlePageChange(page) {
      this.setCurrentPage(page)
      this.$store.dispatch('company/shipments/fetchShipments')
    },
    handleSortChange(payload) {
      this.setSorting(payload)
      this.handlePageChange(1)
    },
    handleLimitChange(newPageLimit) {
      const { start: currentStartIndex } = this.findIndexes(
        this.currentPage,
        this.pageLimit
      )
      let newPage = 1

      let { start } = this.findIndexes(newPage, newPageLimit)
      while (start < currentStartIndex) {
        newPage++
        start = this.findIndexes(newPage, newPageLimit).start
      }

      this.setPageLimit(newPageLimit)
      this.setCurrentPage(newPage)
      this.$store.dispatch('company/shipments/fetchShipments')
    },
    updateQueryParams() {
      if (this.$actions.pending('company/shipments/fetchShipments')) return

      const pathWithQueryParams = {
        path: this.$route.path,
        query: { p: this.currentPage, r: this.pageLimit }
      }

      this.$router.push(pathWithQueryParams)
    }
  }
}
</script>
